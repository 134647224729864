<p>Time: {{ this.getTimeElapsed() }}</p>
<p>Score: {{ this.getScore() }}</p>
<p>Speed: {{ this.getSpeed() }}</p>

<section class="controls" *ngIf="!isOver()">
  <button (click)="togglePause()">{{ this.isPaused() ? 'Continue' : 'Pause' }}</button>
  <button (click)="reset()">Reset</button>
</section>
<section class="controls" *ngIf="isOver()">
  <p><strong>Game over!</strong></p>
  <p>You scored <strong>{{ this.getScore() }}</strong></p>
  <button (click)="reset()">Try again</button>
</section>
