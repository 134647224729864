import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { GlassComponent } from './glass/glass.component';
import { NextComponent } from './next/next.component';
import { PixelComponent } from './board/pixel/pixel.component';
import { BoardComponent } from './board/board.component';
import { StatusComponent } from './status/status.component';

@NgModule({
  declarations: [
    AppComponent,
    GlassComponent,
    NextComponent,
    PixelComponent,
    BoardComponent,
    StatusComponent,
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
