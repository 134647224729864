import { Component, OnInit } from '@angular/core';
import { GameService } from '../game.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  constructor(
    private gameService: GameService,
  ) { }

  ngOnInit(): void {
  }

  getScore(): number {
    return this.gameService.score;
  }

  getTimeElapsed(): number {
    return Math.ceil(this.gameService.timeElapsed / 1000);
  }

  getSpeed(): number {
    return this.gameService.speed;
  }

  togglePause(): void {
    this.gameService.togglePause();
  }

  reset(): void {
    this.gameService.init();
  }

  isPaused(): boolean {
    return this.gameService.isPaused();
  }

  isOver(): boolean {
    return this.gameService.isOver;
  }

}
